import { render, staticRenderFns } from "./CenterModal.vue?vue&type=template&id=7974c0e5&scoped=true&"
import script from "./CenterModal.vue?vue&type=script&lang=js&"
export * from "./CenterModal.vue?vue&type=script&lang=js&"
import style0 from "./CenterModal.vue?vue&type=style&index=0&id=7974c0e5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7974c0e5",
  null
  
)

export default component.exports